import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faChevronLeft, faChevronRight, faEllipsisV, faGlobe, faSignOutAlt, faTableList, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { faBattleNet } from '@fortawesome/free-brands-svg-icons';
import NuudayImg from '../../assets/images/logo.webp';
import { NavLink, Link } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../../config';
import { UseAuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { hasAdminWriteAccess } from '../../apis/access';

export default function Main(props) {
  const { i18n } = useTranslation();
  const { instance, accounts } = useMsal();
  const [sideEnable, setSideEnable] = useState(true);
  const [mobileEnable, setMobileEnable] = useState(false);
  const { token, setToken } = UseAuthContext();
  const [access, setAccess] = useState(false);

  useEffect(() => {
    if (accounts.length > 0) {
      instance.acquireTokenSilent({ ...loginRequest, account: accounts[0] }).then((response) => {
        setToken(response.accessToken);
      }).catch((e) => {
        instance.acquireTokenPopup({ ...loginRequest, account: accounts[0] }).then((response) => {
          setToken(response.accessToken);
        });
      });
    }
  }, [accounts, instance, setToken])

  useEffect(() => {
    setAccess(hasAdminWriteAccess(token));
  }, [token])

  const logoutHandler = (instance) => {
    sessionStorage.removeItem("nam_token");
    instance.logoutPopup().catch(e => {
      console.error(e);
    });
  }

  const handleLang = (val) => {
    if (val.includes("en")) {
      i18n.changeLanguage('dk');
    } else {
      i18n.changeLanguage('en');
    }
  }

  return (
    <div className="wrapper">
      <div className={mobileEnable ? "body-overlay show-nav" : "body-overlay"} onClick={() => setMobileEnable(false)} />
      <nav id="sidebar" className={mobileEnable ? "show-nav" : sideEnable ? "" : "active"}>
        <div className="sidebar-header">
          <h3><img src={NuudayImg} className="img-fluid" alt="logo" /><span>Nuuday</span> </h3>
        </div>
        <ul className="list-unstyled components">
          <li className="dropdown d-lg-none d-md-block d-xl-none d-sm-block active small-screen navbar-display">
            <a href="#homeSubmenu0" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
              <FontAwesomeIcon icon={faUserCircle} size="xl" className="mx-3" /><span>{accounts[0]?.name}</span></a>
            <ul className="collapse list-unstyled menu" id="homeSubmenu0">
              <li onClick={() => logoutHandler(instance)}>
                <NavLink to="#" >
                  <FontAwesomeIcon icon={faSignOutAlt} className="ml-4 mr-2" size="lg" /><span>Logout</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/nam" className={({ isActive }) => (isActive ? 'selected' : '')}>
              <FontAwesomeIcon icon={faAddressCard} size="xl" className="mx-3" />
              <span>NAM</span>
            </NavLink>
          </li>
          <li>
           <NavLink to="/fallout" className={({ isActive }) => (isActive ? 'selected' : '')}>
             <FontAwesomeIcon icon={faTableList} size="xl" className="mx-3" />
             <span>Fallout</span>
           </NavLink>
          </li>
          <li>
            <NavLink to="/nam-swagger" className={({ isActive }) => (isActive ? 'selected' : '')}>
              <FontAwesomeIcon icon={faBattleNet} size="xl" className="mx-3" />
              <span>Swagger</span>
            </NavLink>
          </li>
        </ul>
      </nav>
      <div id="content" className={sideEnable ? "" : "active"}>
        <div className="top-navbar">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <button type="button" id="sidebarCollapse" className="d-xl-block d-lg-block d-md-mone d-none"
                onClick={() => setSideEnable(pre => !pre)}>
                {sideEnable ? <FontAwesomeIcon icon={faChevronLeft} size="lg" /> : <FontAwesomeIcon icon={faChevronRight} size="lg" />}
              </button>
              <Link className="navbar-brand" to="/nam"> NAM Dashboard </Link>
              <button className="d-inline-block d-lg-none ml-auto more-button" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                onClick={() => setMobileEnable(true)}>
                <FontAwesomeIcon icon={faEllipsisV} size="2x" color="#007bff" />
              </button>
              <div className="collapse navbar-collapse d-lg-block d-xl-block d-sm-none d-md-none d-none" id="navbarSupportedContent">
                <ul className="nav navbar-nav ml-auto">
                  <li className="dropdown nav-item">
                    <a href={window.location} className="nav-link" data-toggle="dropdown">
                      <FontAwesomeIcon icon={faUserCircle} size="2x" /> <span style={{ verticalAlign: 'super' }}>{accounts[0]?.name}</span>
                    </a>
                    <ul className="dropdown-menu">
                      <li onClick={() => logoutHandler(instance)} style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon icon={faSignOutAlt} size="lg" color="#2196F3" className="mx-3" />
                        <span style={{ color: "#2196F3" }}>Logout</span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => handleLang(i18n.language)}><FontAwesomeIcon icon={faGlobe}
              /> {i18n.language === "dk" ? "en" : "dk"}</button>
            </div>
          </nav>
        </div>
        {props.children}
      </div>
    </div>
  )
}
