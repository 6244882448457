import React, { useEffect, useState } from 'react';
import Create from './Create';
import Search from './Search';
import ResultTable from './ResultTable';
import { hasAdminWriteAccess } from '../../apis/access';
import { UseAuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import SearchById from './SearchById';
import UnofficialFallout from './UnofficialFallout';
import FalloutTable from './FalloutTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderPlus, faSearch, faSyncAlt, faUnlink } from '@fortawesome/free-solid-svg-icons';
import GksSync from './gks/GksSync';
import GksPendingTable from './gks/GksPendingTable';

export default function Details() {
    const { t } = useTranslation();
    const { token } = UseAuthContext();
    const [access, setAccess] = useState(false);
    const [activeTab, setActiveTab] = useState("search_address");
    const [resultData, setResultData] = useState({
        data: null,
        loading: false,
        msg: ""
    });
    const [pageSubmit, setPageSubmit] = useState();

    useEffect(() => {
        setAccess(hasAdminWriteAccess(token));
    }, [token])
    const resetResult = () => {
        setResultData({
            data: null,
            loading: false,
            msg: ""
        });
    }
    return (
        <div className="main-content">
            <div className="row ">
                <div className="col-lg-12 col-md-12">
                    <div className="card" style={{ minHeight: 200 }}>
                        <div className="card-content">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link mr-1 active" id="search-tab-address" style={{ minWidth: "10rem" }} data-toggle="tab" href="#search_address" role="tab" aria-controls="search" aria-selected="true"
                                        onClick={() => { setActiveTab("search_address"); resetResult(); }}>
                                        {t('search.search_address')}
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link mr-1" id="search-tab-id" data-toggle="tab" href="#search_id" style={{ minWidth: "12rem" }} role="tab" aria-controls="search" aria-selected="true"
                                        onClick={() => { setActiveTab("search_id"); resetResult(); }}>
                                        {t('search.search_id')}
                                    </button>
                                </li>
                                {/*{access && <li className="nav-item" role="presentation">*/}
                                {/*    <button className="nav-link mr-1 disabled" id="create-tab" data-toggle="tab" href="#create" role="tab" style={{ minWidth: "12rem" }} aria-controls="create" aria-selected="true"*/}
                                {/*        onClick={() => { setActiveTab("create"); resetResult(); }}>*/}
                                {/*        {t('create.header')}*/}
                                {/*    </button>*/}
                                {/*</li>}*/}
                                {/*{access && <li className="nav-item" role="presentation">*/}
                                {/*    <button className="nav-link mr-1 disabled" id="fallout-tab" data-toggle="tab" href="#fallout" role="tab" style={{ minWidth: "12rem" }} aria-controls="fallout" aria-selected="true"*/}
                                {/*        onClick={() => { setActiveTab("fallout"); resetResult(); }}>*/}
                                {/*        {t('fallout.fallout_address')}*/}
                                {/*    </button>*/}
                                {/*</li>}*/}
                                {/*{access && <li className="nav-item" role="presentation">*/}
                                {/*    <button className="nav-link disabled" id="gks_sync-tab" data-toggle="tab" href="#gks_sync" role="tab" style={{ minWidth: "12rem" }} aria-controls="gks_sync" aria-selected="true"*/}
                                {/*        onClick={() => { setActiveTab("gks_sync"); resetResult(); }}>*/}
                                {/*        {t('gks_sync.title')}*/}
                                {/*    </button>*/}
                                {/*</li>}*/}
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="search_address" role="tabpanel" aria-labelledby="search-tab">
                                    {activeTab === "search_address" && <Search setResultData={setResultData} activeTab={activeTab} setSearchSubmit={setPageSubmit} />}
                                </div>
                                <div className="tab-pane fade" id="search_id" role="tabpanel" aria-labelledby="search-tab">
                                    {activeTab === "search_id" && <SearchById setResultData={setResultData} activeTab={activeTab} setSearchByIdSubmit={setPageSubmit} />}
                                </div>
                                {access && <div className="tab-pane fade" id="create" role="tabpanel" aria-labelledby="create-tab">
                                    {activeTab === "create" && <Create setResultData={setResultData} activeTab={activeTab} />}
                                </div>}
                                {access && <div className="tab-pane fade" id="fallout" role="tabpanel" aria-labelledby="fallout-tab">
                                    {activeTab === "fallout" && <UnofficialFallout setResultData={setResultData} activeTab={activeTab} setFalloutSubmit={setPageSubmit} />}
                                </div>}
                                {access && <div className="tab-pane fade" id="gks_sync" role="tabpanel" aria-labelledby="gks_sync-tab">
                                    {activeTab === "gks_sync" && <GksSync setResultData={setResultData} activeTab={activeTab} setGksSubmit={setPageSubmit} />}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12">
                    <div className="card" style={{ minHeight: 200, fontSize: "0.8rem" }}>

                        {activeTab === "search_address" &&
                            (resultData.data?.content?.length > 0 ? <ResultTable tableData={resultData.data} pageSubmit={pageSubmit} />
                                : <div className="text-center py-4">
                                    <FontAwesomeIcon icon={faSearch} size="6x" color="#6c757d" spin={resultData.loading} />
                                    <h3 className="mt-3">{resultData.msg ? resultData.msg : t('result.searchMsg')}</h3>
                                </div>)}

                        {activeTab === "search_id" &&
                            (resultData.data?.content?.length > 0 ? <ResultTable tableData={resultData.data} pageSubmit={pageSubmit} />
                                : <div className="text-center py-4">
                                    <FontAwesomeIcon icon={faSearch} size="6x" color="#6c757d" spin={resultData.loading} />
                                    <h3 className="mt-3">{resultData.msg ? resultData.msg : t('result.searchIdMsg')}</h3>
                                </div>)}
                        {activeTab === "create" &&
                            (resultData.data?.content?.length > 0 ? <ResultTable tableData={resultData.data} pageSubmit={pageSubmit} />
                                : <div className="text-center py-4">
                                    <FontAwesomeIcon icon={faFolderPlus} size="6x" color="#6c757d" spin={resultData.loading} />
                                    <h3 className="mt-3">{resultData.msg ? resultData.msg : t('result.createMsg')}</h3>
                                </div>)}
                        {activeTab === "fallout" &&
                            (resultData.data?.content?.length > 0 ? <FalloutTable tableData={resultData.data} pageSubmit={pageSubmit} />
                                : <div className="text-center py-4">
                                    <FontAwesomeIcon icon={faUnlink} size="6x" color="#6c757d" spin={resultData.loading} />
                                    <h3 className="mt-3">{resultData.msg ? resultData.msg : t('result.falloutMsg')}</h3>
                                </div>)}
                        {activeTab === "gks_sync" &&
                            (resultData.data?.content?.length > 0 ? <GksPendingTable tableData={resultData.data} syncType={resultData?.sync} pageSubmit={pageSubmit} />
                                : <div className="text-center py-4">
                                    <FontAwesomeIcon icon={faSyncAlt} size="7x" color="#6c757d" spin={resultData.loading} />
                                    <h3 className="mt-3">{resultData.msg ? resultData.msg : t('gks_sync.title')}</h3>
                                </div>)}
                    </div>
                </div>
            </div>
        </div>

    )
}
