import React from 'react';
import NuudayImg from '../assets/images/logo.webp'
import '../assets/css/login.css';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../config";

function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

export default function Login() {
    const { instance } = useMsal();

    return (
        <div className="login-page">
            {console.log(process.env.REACT_APP_ENV, process.env.REACT_APP_REDIRECT_URL)}
            <div className="login-container">
                <div className="d-flex justify-content-center h-100">
                    <div className="card login-card" >
                        <div className="card-header">
                            <h3>Sign In</h3>
                            <div className="d-flex justify-content-end social_icon">
                                <img src={NuudayImg} className="img-fluid" alt="logo" />
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <button className="btn btn-block login_btn" onClick={() => handleLogin(instance)}>NAM Dashboard</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
