import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { UseAuthContext } from '../../context/AuthContext';
import { validateSearch, getErrorMsg } from '../utils/commonUtil';

export default function Search({ setResultData, setSearchSubmit }) {
    const { t } = useTranslation();
    const { token } = UseAuthContext();
    const [load, setLoad] = useState(false);
    const [validList, setValidList] = useState([]);
    const [searchParams, setSearchParams] = useState({
        postCode: "", //5
        streetBuildingId: "", //2
        municipalityName: "", //8
        streetName: "", //1
        districtName: "", //6
        districtSubdivisionIdentifier: "", // 7
        isOfficial: "", //9
        floorId: "", //3
        suiteId: "", //4
    });
    const [suggestion, setSuggestion] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const [pageInfo, setPageInfo] = useState({
        page: 0,
        size: "5"
    });

    const handleInput = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        setSearchParams(pre => ({ ...pre, [id]: value }));
    }

    useEffect(() => {
        setValidList(validateSearch(searchParams))
    }, [searchParams])

    const verifyValid = (name) => {
        return validList.includes(name) ? <span>*</span> : null;
    }

    const resetAll = () => {
        setSearchParams({
            postCode: "", //5
            streetBuildingId: "", //2
            municipalityName: "", //8
            streetName: "", //1
            districtName: "", //6
            districtSubdivisionIdentifier: "", // 7
            isOfficial: "", //9
            floorId: "", //3
            suiteId: "", //4
        });
        setPageInfo({
            page: 0,
            size: "5"
        })
        setResultData({ data: null, loading: false, msg: "" });
        setSuggestion('');
        setSuggestions([]);
    }

    const searchHandler = (pageNo) => {
        setLoad(true);
        setResultData({ data: null, loading: true, msg: t('result.loading') });
        axios.post(`/api/v2/addresses/legacy?page=${pageNo}&size=${pageInfo.size}`,
            {
                ...searchParams, floorId: searchParams.floorId ? searchParams.floorId : null,
                suiteId: searchParams.suiteId ? searchParams.suiteId : null
            }, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                if (res.data.content.length === 0) {
                    setResultData({ data: null, loading: false, msg: t('result.no_data') });
                } else {
                    setResultData({ data: res.data, loading: false, msg: "" });
                }
                setLoad(false);
            })
            .catch(err => {
                setResultData({ data: null, loading: false, msg: getErrorMsg(err.message) });
                setLoad(false);
            })
    }
    //&& (val.length % 2) === 0 for alternative key
    const handleSuggestion = (val) => {
        if (val && val.length > 3) {
            axios.get(`/api/v1/address/suggest?input=${val}`, { headers: { "Authorization": `Bearer ${token}` } }).then(res => {
                if (res.data && res.data.length > 0) {
                    setSuggestions(res.data.map(d => {
                        return { 'key': d, 'value': d.replaceAll(';', ' ') };
                    }));
                }
            })
        }
        setSuggestion(val);
        registerAllFields(val);
    }

    const registerAllFields = (val) => {
        if (val) {
            let filterData = suggestions.filter(f => f.value === val);
            let fields = filterData[0] ? filterData[0].key.split(';') : '';
            if (fields.length > 2) {
                setSearchParams(pre => ({
                    ...pre,
                    streetName: fields[0] ? fields[0] : '',
                    streetBuildingId: fields[1] ? fields[1] : '',
                    postCode: fields[2] ? fields[2] : '',
                    floorId: fields[3] ? fields[3] : '',
                    suiteId: fields[4] ? fields[4] : ''
                }))
            }
        }
    }

    return (
        <div className="mt-2 text-sm">
            <div className="form-row">
                <div className='form-group col-md-12'>
                    <label htmlFor='suggestion'>Search For Suggested Address</label>
                    <input list="suggestions" className='form-control form-control-sm' name="suggestion" id="suggestion"
                        value={suggestion} onChange={(e) => handleSuggestion(e.target.value)} autocomplete="off" />

                    <datalist id="suggestions">
                        {suggestions.map((s, i) => <option key={i} value={s.value} />)}
                    </datalist>

                </div>
                <div className="form-group col-md">
                    <label htmlFor="streetName">{t('search.street_name')}{verifyValid("streetName")}</label>
                    <input type="text" className="form-control form-control-sm" id="streetName" maxLength="40"
                        value={searchParams.streetName} onChange={handleInput} />
                </div>
                <div className="form-group col-md">
                    <label htmlFor="streetBuildingId">{t('search.building_id')}{verifyValid("streetBuildingId")}</label>
                    <input type="text" className="form-control form-control-sm" id="streetBuildingId" maxLength="4"
                        value={searchParams.streetBuildingId} onChange={handleInput} />
                </div>
                <div className="form-group col-md">
                    <label htmlFor="floor">{t('search.floor')}{verifyValid("floorId")}</label>
                    <input type="text" className="form-control form-control-sm" id="floorId" maxLength="2"
                        value={searchParams.floorId} onChange={handleInput} />
                </div>
                <div className="form-group col-md">
                    <label htmlFor="suite">{t('search.suite')}{verifyValid("suiteId")}</label>
                    <input type="text" className="form-control form-control-sm" id="suiteId" maxLength="4"
                        value={searchParams.suiteId} onChange={handleInput} />
                </div>
                <div className="form-group col-md">
                    <label htmlFor="post">{t('search.post_code')}{verifyValid("postCode")}</label>
                    <input type="text" className="form-control form-control-sm" id="postCode" maxLength="4"
                        value={searchParams.postCode} onChange={handleInput} />
                </div>

            </div>
            <div className="form-row">

                <div className="form-group col-md">
                    <label htmlFor="district">{t('search.district')}{verifyValid("districtName")}</label>
                    <input type="text" className="form-control form-control-sm" id="districtName" maxLength="40"
                        value={searchParams.districtName} onChange={handleInput} />
                </div>

                <div className="form-group col-md">
                    <label htmlFor="districtSubdivisionIdentifier">{t('search.district_subdivision')}{verifyValid("districtSubdivisionIdentifier")}</label>
                    <input type="text" className="form-control form-control-sm" id="districtSubdivisionIdentifier" maxLength="50"
                        value={searchParams.districtSubdivisionIdentifier} onChange={handleInput} />
                </div>

                <div className="form-group col-md">
                    <label htmlFor="municipalityName">{t('search.municipality_name')}{verifyValid("municipalityName")}</label>
                    <input type="text" className="form-control form-control-sm" id="municipalityName" maxLength="40"
                        value={searchParams.municipalityName} onChange={handleInput} />
                </div>

                <div className="form-group col-md">
                    <label htmlFor="isOfficial">{t('search.isOfficial')}</label>
                    <select id="isOfficial" className="form-control form-control-sm"
                        value={searchParams.isOfficial} onChange={handleInput}>
                        <option value="" defaultValue>All</option>
                        <option value="0" >Official</option>
                        <option value="1" >TdcNet Unofficial</option>
                        <option value="8" >Nuuday Unofficial</option>
                    </select>
                </div>
                <div className="form-group col-md">
                    <label htmlFor="size">{t('search.records')}</label>
                    <select id="size" className="form-control form-control-sm"
                        value={pageInfo.size} onChange={(e) => setPageInfo(pre => ({ page: 0, size: e.target.value }))}>
                        <option value="5" >5</option>
                        <option value="10" >10</option>
                        <option value="15" >15</option>
                        <option value="20" >20</option>
                        <option value="25" >25</option>
                        <option value="50" >50</option>
                    </select>
                </div>

            </div>
            <div className="row gy-1">
                <div className="col-6 text-right">
                    <button className="btn btn-primary"
                        disabled={load || validList.length === 0 || validList.some(v => searchParams[v].trim().length === 0)}
                        onClick={() => { searchHandler(0); setSearchSubmit(() => searchHandler) }}>{load ? t('search.wait') : t('search.search')}</button>
                </div>
                <div className="col-6 text-left">
                    <button className="btn btn-secondary ml-1" onClick={() => resetAll()}>{t('search.reset')}</button>
                </div>
            </div>

        </div>
    )
}
