import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UseAuthContext } from '../../context/AuthContext';
import { findSpacialChar, getErrorMsg } from '../utils/commonUtil';

export default function Create({ setResultData, activeTab }) {
    const { t } = useTranslation();
    const { token } = UseAuthContext();
    const [load, setLoad] = useState(false);
    const [createAddress, setCreateAddress] = useState({
        streetName: "",
        streetCode: "",
        streetBuildingId: "",
        floorId: "",
        suiteId: "",
        postCode: "",
        districtSubdivision: "",
        municipalityName: "",
        gks: false
    });

    useEffect(() => {
        setCreateAddress({
            streetName: "",
            streetCode: "",
            streetBuildingId: "",
            floorId: "",
            suiteId: "",
            postCode: "",
            districtSubdivision: "",
            municipalityName: "",
            gks: false
        });
    }, [activeTab]);

    const handleInput = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        setCreateAddress(pre => ({ ...pre, [id]: value }));
    }

    const resetAddress = () => {
        setCreateAddress({
            streetName: "",
            streetCode: "",
            streetBuildingId: "",
            floorId: "",
            suiteId: "",
            postCode: "",
            districtSubdivision: "",
            municipalityName: "",
            gks: false
        });
        setLoad(false);
    }

    const resetResult = () => setResultData({ data: null, loading: false, msg: "" });

    const validateInput = () => {
        return (findSpacialChar(createAddress.streetName) && findSpacialChar(createAddress.streetCode) && findSpacialChar(createAddress.streetBuildingId)
            && findSpacialChar(createAddress.floorId) && findSpacialChar(createAddress.suiteId) && findSpacialChar(createAddress.postCode) && findSpacialChar(createAddress.districtSubdivision)
            && findSpacialChar(createAddress.municipalityName));
    }

    const createHandle = () => {
        setLoad(true);
        setResultData({ data: null, loading: true, msg: t('result.loading') });
        if (validateInput()) {
            axios.post("/api/v1/specific/createSpecificAddress", createAddress, { headers: { "Authorization": `Bearer ${token}` } })
                .then(res => {
                    console.log(res.data);
                    setResultData({ data: { content: [res.data], number: 0, totalPages: 1 }, loading: false, msg: "" });
                    resetAddress();
                    setLoad(false);
                }).catch(err => {
                    if (err.response.data.message) {
                        setResultData({ data: null, loading: false, msg: err.response.data.message });
                    } else {
                        setResultData({ data: null, loading: false, msg: getErrorMsg(err.message) });
                    }
                    setLoad(false);
                })
        } else {
            setLoad(false);
            setResultData({
                data: null, loading: false, msg: t('create.invalid_char')
            });
        }
    }

    return (
        <div className="mt-2">
            <div className="form-row">
                <div className="form-group col-md-3">
                    <label htmlFor="streetName">{t('create.street_name')}*</label>
                    <input type="text" className="form-control form-control-sm" id="streetName" maxLength="40"
                        value={createAddress.streetName} onChange={handleInput}
                        onKeyDown={(e) => !findSpacialChar(e.key) && e.preventDefault()} />
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="streetCode">{t('create.street_code')}*</label>
                    <input type="text" className="form-control form-control-sm" id="streetCode" maxLength="4"
                        value={createAddress.streetCode} onChange={handleInput}
                        onKeyDown={(e) => !findSpacialChar(e.key) && e.preventDefault()} />
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="streetBuildingId">{t('create.building_id')}*</label>
                    <input type="text" className="form-control form-control-sm" id="streetBuildingId" maxLength="4"
                        value={createAddress.streetBuildingId} onChange={handleInput}
                        onKeyDown={(e) => !findSpacialChar(e.key) && e.preventDefault()} />
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="floorId">{t('create.floor')}</label>
                    <input type="text" className="form-control form-control-sm" id="floorId" maxLength="2"
                        value={createAddress.floorId} onChange={handleInput}
                        onKeyDown={(e) => !findSpacialChar(e.key) && e.preventDefault()} />
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="suiteId">{t('create.suite')}</label>
                    <input type="text" className="form-control form-control-sm" id="suiteId" maxLength="4"
                        value={createAddress.suiteId} onChange={handleInput}
                        onKeyDown={(e) => !findSpacialChar(e.key) && e.preventDefault()} />
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="postCode">{t('create.post_code')}*</label>
                    <input type="text" className="form-control form-control-sm" id="postCode" maxLength="4"
                        value={createAddress.postCode} onChange={handleInput}
                        onKeyDown={(e) => !findSpacialChar(e.key) && e.preventDefault()} />
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="districtSubdivision">{t('create.district_subdivision')}</label>
                    <input type="text" className="form-control form-control-sm" id="districtSubdivision" maxLength="50"
                        value={createAddress.districtSubdivision} onChange={handleInput}
                        onKeyDown={(e) => !findSpacialChar(e.key) && e.preventDefault()} />
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="municipalityName">{t('create.municipality')}*</label>
                    <input type="text" className="form-control form-control-sm" id="municipalityName" maxLength="40"
                        value={createAddress.municipalityName} onChange={handleInput}
                        onKeyDown={(e) => !findSpacialChar(e.key) && e.preventDefault()} />
                </div>

            </div>

            <div className="row">
                <div className="col-6 text-right">
                    <button className="btn btn-primary" onClick={() => createHandle()}
                        disabled={load || !(createAddress.municipalityName.trim() && createAddress.municipalityName.trim()
                            && createAddress.postCode.trim() && createAddress.streetCode.trim() && createAddress.streetName.trim() && createAddress.streetBuildingId.trim())}
                    >{load ? t('create.wait') : t('create.create')}</button>
                </div>
                <div className="col-6 text-left">
                    <button className="btn btn-secondary ml-1" onClick={() => { resetAddress(); resetResult() }}>{t('create.reset')}</button>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 text-center">
                    <div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={createAddress.gks === true}
                                onChange={() => setCreateAddress(pre => ({ ...pre, gks: !pre.gks }))} />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                {t('create.gks')}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
