import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/css/custom.css';
import App from './route/App';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config";
import './components/utils/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <Suspense fallback={<div className="text-center py-4"><FontAwesomeIcon icon={faSpinner} spin size="7x" color="blue" /></div>}>
    <React.StrictMode>
      <MsalProvider instance={msalInstance} forceLogin={true}>
        <App />
      </MsalProvider>
    </React.StrictMode>
  </Suspense>
  ,
  document.getElementById('root')
);
