import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFileExport, faSave, faSpinner, faTimes, faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { UseAuthContext } from '../../context/AuthContext';
import { hasAdminWriteAccess } from '../../apis/access';
import { useTranslation } from 'react-i18next';
import { CSVLink } from "react-csv";
import axios from 'axios';
import { findSpacialChar, getErrorMsg } from '../utils/commonUtil';

export default function ResultTable({ tableData, pageSubmit }) {
    const { t } = useTranslation();
    const { token } = UseAuthContext();
    const [data, setData] = useState([]);
    const [access, setAccess] = useState(false);
    const [item, setItem] = useState(null);
    const [csvData, setCsvData] = useState([]);
    const [load, setLoad] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [page, setPage] = useState(1);
    const [goto, setGoto] = useState(1);

    useEffect(() => {
        setAccess(hasAdminWriteAccess(token));
    }, [token]);

    useEffect(() => {
        setData(tableData.content);
        setPage(tableData.number);
        setGoto(tableData.number + 1);
        setErrMsg("");
    }, [tableData]);

    const handleEdit = (d) => {
        setItem({
            "id": d.id,
            "streetName": d.accessAddress.streetId.name, //
            "streetCode": d.accessAddress.streetId.code, //
            "streetBuildingId": d.accessAddress.streetBuildingId, //
            "floorId": d.floorId ? d.floorId : "", //
            "suiteId": d.suiteId ? d.suiteId : "", //
            "isDeleted": d.isDeleted,
            "postCode": d.accessAddress.postCodeId.id,
            "districtSubdivision": d.accessAddress?.districtSubDivId,
            "municipalityName": d.accessAddress.streetId.municipality.municipalityName
        });
    }

    const csvHeader = [t('result.table.street_name'),
    t('result.table.street_code'),
    t('result.table.building_id'),
    t('result.table.floor'),
    t('result.table.suite'),
    t('result.table.post_code'),
    t('result.table.district'),
    t('result.table.district_subdivision'),
    t('result.table.region'),
    t('result.table.municipality_name'),
    t('result.table.official'),
    t('result.table.deleted'),
    t('result.table.kvhx_id'),
    t('result.table.mad_id'),
    t('result.table.address_id')];

    const getCsvData = (event, done) => {
        if (data.length > 0) {
            setCsvData([csvHeader, ...data.map(d => {
                return [
                    d.accessAddress.streetId.name,
                    d.accessAddress.streetId.code,
                    d.accessAddress.streetBuildingId,
                    d?.floorId,
                    d?.suiteId,
                    d.accessAddress.postCodeId.id,
                    d.accessAddress.postCodeId.districtName,
                    d.accessAddress.districtSubDivId,
                    d.accessAddress.streetId.municipality.region.name,
                    d.accessAddress.streetId.municipality.municipalityName,
                    d.isOfficial,
                    d.isDeleted,
                    d.kvhxId.toString(),
                    d.madId,
                    d.id.includes("access") ? "" : d.id
                ]
            })]);
        }
        done(true);
    }

    const handleInput = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        setItem(pre => ({ ...pre, [id]: value }));
    }

    const invalidChars = [
        "-",
        "+",
        "e",
        "."
    ];

    const validateInput = () => {
        return (findSpacialChar(item.streetName) && findSpacialChar(item.streetCode) && findSpacialChar(item.streetBuildingId)
            && findSpacialChar(item.floorId) && findSpacialChar(item.suiteId));
    }

    const saveEdit = (e) => {
        setLoad(true);
        setErrMsg("");
        const headers = { headers: { "Authorization": `Bearer ${token}` } };
        //update specific
        if (validateInput()) {
            axios.put(`/api/v2/address/update/specific`, e, headers).then(res => {
                setLoad(false);
                setData(pre => pre.map(d => d.id === res.data.id ? res.data : d));
                setItem(null);
            }).catch(err => {
                setLoad(false);
                if (err.response.data.message) {
                    setErrMsg(err.response.data.message);
                } else {
                    setErrMsg(getErrorMsg(err.message));
                }
            })
        } else {
            setLoad(false);
            setErrMsg(t('create.invalid_char'));
        }
    }

    const hasUnOfficial = (data) => data.length > 0 &&
        data.map(d => d.isOfficial).find(d => d === "8");

    const officialStatus = {
        "0": "Official",
        "1": "TdcNet Unofficial",
        "8": "Nuuday Unofficial"
    }

    const handleGoto = () => {
        if (goto < 1) {
            setErrMsg(t('result.page_lesser'));
        } else if (goto > tableData.totalPages) {
            setErrMsg(t('result.page_greater'));
        } else {
            pageSubmit(goto - 1)
        }
    }

    return (
        <div className="card-content">
            <div className="row mb-2">
                <div className="col-sm-1 col-2 text-left">
                    <button className="btn btn-primary btn-sm" disabled={page < 1} onClick={() => pageSubmit(page - 1)} ><FontAwesomeIcon icon={faArrowAltCircleLeft} size="xl" /></button>
                </div>
                <div className="col-sm-10 col-8">
                    <div className="form-group row text-center ">
                        <label htmlFor="staticEmail" className="col-sm-2 col-12 col-form-label"> {t('result.page')}: {page + 1} {t('result.of')} {tableData.totalPages}</label>
                        <label htmlFor="staticEmail" className="col-sm-3 col-8 col-form-label text-right"> {t('result.goto')}: </label>
                        <div className="col-sm-2 col-4">
                            <input type="number" className="form-control form-control-sm input-sm" id="page"
                                onKeyDown={(e) => invalidChars.includes(e.key) && e.preventDefault()}
                                value={goto} onChange={(e) => setGoto(e.target.value)} />
                        </div>
                        <div className="col-sm-1 col-12 mb-1">
                            <button className="btn btn-secondary btn-sm " onClick={() => handleGoto()}>{t('result.go')}</button>
                        </div>
                        <div className="col-sm-4 col-12">
                            <CSVLink data={csvData} filename={`nam-address-page-${page}.csv`}
                                asyncOnClick={true}
                                onClick={getCsvData}>
                                <button className="btn btn-sm btn-primary text-right">{t('result.export')} <FontAwesomeIcon icon={faFileExport} /></button>
                            </CSVLink>
                        </div>
                    </div>
                </div>
                <div className="col-sm-1 col-2 text-right">
                    <button className="btn btn-primary btn-sm" disabled={page + 1 >= tableData.totalPages} onClick={() => pageSubmit(page + 1)}><FontAwesomeIcon icon={faArrowAltCircleRight} size="xl" /></button>
                </div>

                <div className="col-12 text-center">
                    {errMsg && <div className="alert alert-danger" style={{ marginBottom: "0", padding: "0.3rem" }} role="alert">{errMsg}</div>}
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-hover table-sm table-striped table-bordered" style={{ whiteSpace: "nowrap" }}>
                    <thead>
                        <tr className="table-active" >
                            {access && hasUnOfficial(data)
                                && <th>{t('result.table.edit')}</th>}
                            <th>{t('result.table.street_name')}</th>
                            <th>{t('result.table.street_code')}</th>
                            <th>{t('result.table.building_id')}</th>
                            <th>{t('result.table.floor')}</th>
                            <th>{t('result.table.suite')}</th>
                            <th>{t('result.table.post_code')}</th>
                            <th>{t('result.table.district')}</th>
                            <th>{t('result.table.district_subdivision')}</th>
                            <th>{t('result.table.region')}</th>
                            <th>{t('result.table.municipality_name')}</th>
                            <th>{t('result.table.official')}</th>
                            <th>{t('result.table.deleted')}</th>
                            <th>{t('result.table.kvhx_id')}</th>
                            <th>{t('result.table.mad_id')}</th>
                            <th>{t('result.table.address_id')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 && data.map((d, i) => <tr key={i}>
                            {item && (item.id === d.id) ?
                                <React.Fragment>
                                    {load === true ?
                                        <td className="text-center">
                                            <FontAwesomeIcon icon={faSpinner} color="blue" size="lg" spin />
                                        </td> :
                                        <td>
                                            <button className="btn btn-sm btn-success" onClick={() => saveEdit(item)}
                                            ><FontAwesomeIcon icon={faSave} size="sm" /></button>
                                            <button className="btn btn-sm btn-danger ml-2" onClick={() => { setItem(null); setErrMsg("") }}
                                            ><FontAwesomeIcon icon={faTimes} size="sm" /></button>
                                        </td>}
                                    <td><input className="form-control form-control-sm" id="streetName" maxLength="40" value={item.streetName} onChange={handleInput}
                                        onKeyDown={(e) => !findSpacialChar(e.key) && e.preventDefault()} /></td>
                                    <td><input className="form-control form-control-sm" id="streetCode" maxLength="4" value={item.streetCode} onChange={handleInput}
                                        onKeyDown={(e) => !findSpacialChar(e.key) && e.preventDefault()} /></td>
                                    <td><input className="form-control form-control-sm" id="streetBuildingId" maxLength="4" value={item.streetBuildingId} onChange={handleInput}
                                        onKeyDown={(e) => !findSpacialChar(e.key) && e.preventDefault()} /></td>
                                    <td><input className="form-control form-control-sm" id="floorId" maxLength="2" value={item.floorId} onChange={handleInput}
                                        onKeyDown={(e) => !findSpacialChar(e.key) && e.preventDefault()} /></td>
                                    <td><input className="form-control form-control-sm" id="suiteId" maxLength="4" value={item.suiteId} onChange={handleInput}
                                        onKeyDown={(e) => !findSpacialChar(e.key) && e.preventDefault()} /></td>
                                    <td>{d.accessAddress.postCodeId.id}</td>
                                    <td>{d.accessAddress.postCodeId.districtName}</td>
                                    <td>{d.accessAddress.districtSubDivId}</td>
                                    <td>{d.accessAddress.streetId.municipality.region.name}</td>
                                    <td>{d.accessAddress.streetId.municipality.municipalityName}</td>
                                    <td>{officialStatus[d.isOfficial]}</td>
                                    <td><select id="isDeleted" className="form-control form-control-sm" defaultValue={item.isDeleted} onChange={handleInput}>
                                        <option value="Y" >Y</option>
                                        <option value="N" >N</option>
                                    </select></td>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {access && hasUnOfficial(data) &&
                                        <td>{d.isOfficial === "8" && !d.id.includes('access') &&
                                            <button className="btn btn-sm btn-primary" onClick={() => handleEdit(d)}
                                            ><FontAwesomeIcon icon={faEdit} color="2196F3" size="sm" /></button>}
                                        </td>}
                                    <td>{d.accessAddress.streetId.name}</td>
                                    <td>{d.accessAddress.streetId.code}</td>
                                    <td>{d.accessAddress.streetBuildingId}</td>
                                    <td>{d?.floorId}</td>
                                    <td>{d?.suiteId}</td>
                                    <td>{d.accessAddress.postCodeId.id}</td>
                                    <td>{d.accessAddress.postCodeId.districtName}</td>
                                    <td>{d.accessAddress.districtSubDivId}</td>
                                    <td>{d.accessAddress.streetId.municipality.region.name}</td>
                                    <td>{d.accessAddress.streetId.municipality.municipalityName}</td>
                                    <td>{officialStatus[d.isOfficial]}</td>
                                    <td>{d.isDeleted}</td>
                                </React.Fragment>
                            }
                            <td>{d.kvhxId}</td>
                            <td>{d.madId}</td>
                            <td>{d.id.includes("access") ? "" : d.id}</td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
