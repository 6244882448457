import Main from '../components/utils/Main';
import Details from '../components/nam/Details';
import Login from '../logIn/Login';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { AuthContext } from '../context/AuthContext';
import { useState, useEffect } from 'react';
import Swagger from '../components/swagger/Swagger';
import Fallout from '../components/fallout/Fallout';
import { hasAdminWriteAccess } from '../apis/access';



function App() {
  const [token, setToken] = useState(null);
  const [access, setAccess] = useState(false);

  useEffect(() => {
    setAccess(hasAdminWriteAccess(token));
  }, [token])

  return (
    <BrowserRouter>
      <AuthenticatedTemplate>
        <AuthContext.Provider value={{ token, setToken }}>
          <Main>
            <Routes>
              <Route path="/nam-swagger" element={<Swagger />} />
              <Route path="/fallout" element={<Fallout />} />
              <Route path="/nam" element={<Details />} />
              <Route path="/" element={<Navigate to="/nam" />} />
            </Routes>
          </Main>
        </AuthContext.Provider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </BrowserRouter>
  );
}

export default App;
