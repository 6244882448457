import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseAuthContext } from '../../context/AuthContext';
import { getErrorMsg } from '../utils/commonUtil';

export default function UnofficialFallout({ setResultData, setFalloutSubmit }) {
    const { t } = useTranslation();
    const { token } = UseAuthContext();
    const [falloutAddress, setFalloutAddress] = useState({
        streetName: "",
        streetCode: "",
        type: "All",
        user: ""
    });

    const handleInput = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        setFalloutAddress(pre => ({ ...pre, [id]: value }));
    }

    const resetAddress = () => {
        setFalloutAddress({
            streetName: "",
            streetCode: "",
            type: "All",
            user: ""
        });
        setResultData({ data: null, loading: false, msg: "" });
    }

    const [pageInfo, setPageInfo] = useState({
        page: 0,
        size: "10"
    })

    const falloutHandle = (pageNo) => {
        setResultData({ data: [], loading: true, msg: t('result.loading'), page: 0 });
        axios.post(`/api/v1/fallout/unofficialAddress?page=${pageNo}&size=${pageInfo.size}`, falloutAddress, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                console.log(res.data);
                if (res.data.content.length === 0) {
                    setResultData({ data: null, loading: false, msg: t('result.no_data') });
                } else {
                    console.log(res.data.content[0]);
                    setResultData({ data: res.data, loading: false, msg: "" });
                }
            }).catch(err => {
                if (err.response.data.message) {
                    setResultData({ data: null, loading: false, msg: err.response.data.message });
                } else {
                    setResultData({ data: null, loading: false, msg: getErrorMsg(err.message) });
                }
            })
    }

    return (
        <div className="mt-2">
            <div className="form-row">
                <div className="form-group col-md">
                    <label htmlFor="streetName">{t('create.street_name')}</label>
                    <input type="text" className="form-control form-control-sm" id="streetName" maxLength="40"
                        value={falloutAddress.streetName} onChange={handleInput} />
                </div>
                <div className="form-group col-md">
                    <label htmlFor="streetCode">{t('create.street_code')}</label>
                    <input type="text" className="form-control form-control-sm" id="streetCode" maxLength="4"
                        value={falloutAddress.streetCode} onChange={handleInput} />
                </div>
                <div className="form-group col-md">
                    <label htmlFor="type">{t('fallout.type')}</label>
                    <select id="type" className="form-control form-control-sm"
                        value={falloutAddress.type} onChange={handleInput}>
                        <option value="All" >All</option>
                        <option value="Edit" >Edit</option>
                        <option value="Error" >Error</option>
                    </select>
                </div>
                <div className="form-group col-md">
                    <label htmlFor="user">{t('result.table.user')}</label>
                    <input type="text" className="form-control form-control-sm" id="user" maxLength="25"
                        placeholder="Name or mail"
                        value={falloutAddress.user} onChange={handleInput} />
                </div>
                <div className="form-group col-md">
                    <label htmlFor="size">{t('search.records')}</label>
                    <select id="size" className="form-control form-control-sm"
                        value={pageInfo.size} onChange={(e) => setPageInfo(pre => ({ page: 0, size: e.target.value }))}>
                        <option value="10" >10</option>
                        <option value="25" >25</option>
                        <option value="50" >50</option>
                        <option value="100" >100</option>
                    </select>
                </div>

            </div>

            <div className="row">
                <div className="col-6 text-right">
                    <button className="btn btn-primary" onClick={() => { falloutHandle(0); setFalloutSubmit(() => falloutHandle) }}>{t('search.search')}</button>
                </div>
                <div className="col-6 text-left">
                    <button className="btn btn-secondary ml-1" onClick={() => resetAddress()}>{t('create.reset')}</button>
                </div>
            </div>

        </div>
    )
}
