import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseAuthContext } from '../../../context/AuthContext';
import { getErrorMsg } from '../../utils/commonUtil';

export default function GksSync({ setResultData, setGksSubmit }) {
    const { t } = useTranslation();
    const { token } = UseAuthContext();
    const [pageInfo] = useState({
        page: 0,
        size: "100"
    })

    const gksStatus = {
        "COMPLETED": "status=COMPLETED",
        "PENDING": "status=PROCESSING&status=PENDING",
        "FAILED": "status=FAILED"
    }

    const getStatus = (type, pageNo) => {
        setResultData({ data: null, loading: true, msg: "" });
        axios.get(`/api/v1/gks/status?${gksStatus[type]}&page=${pageNo}&size=${pageInfo.size}`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                console.log(res.data)
                if (res.data.content.length === 0) {
                    setResultData({ data: null, loading: false, msg: t('result.no_data') });
                } else {
                    setResultData({ data: res.data, sync: type, loading: false, msg: "" });
                }
            })
            .catch(err => {
                if (err.response.data.message) {
                    setResultData({ data: null, loading: false, msg: err.response.data.message });
                } else {
                    setResultData({ data: null, loading: false, msg: getErrorMsg(err.message) });
                }
            })
    }

    return (
        <div className="mt-4">
            <div className="form-row">
                <div className="col-4 mt-3">
                    <button className="btn btn-block btn-success" onClick={() => { getStatus("COMPLETED", 0); setGksSubmit(() => getStatus) }}>{t('gks_sync.complete')}</button>
                </div>
                <div className="col-4 mt-3">
                    <button className="btn btn-block btn-primary" onClick={() => { getStatus("PENDING", 0); setGksSubmit(() => getStatus) }}>{t('gks_sync.pending')}</button>
                </div>
                <div className="col-4 mt-3">
                    <button className="btn btn-block btn-secondary ml-1" onClick={() => { getStatus("FAILED", 0); setGksSubmit(() => getStatus) }}>{t('gks_sync.error')}</button>
                </div>
            </div>
        </div>
    )
}
