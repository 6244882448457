
export const hasAccess = (token) => {
    if (token) {
        let roles = JSON.parse(window.atob(token.split(".")[1])).roles;
        if (roles) {
            if (typeof roles === "string") {
                return roles.includes("Admin");
            } else {
                return roles.find(role => role.includes("Admin")) ? true : false;
            }
        } else {
            return false;
        }

    } else {
        return false;
    }
}


export const hasAdminWriteAccess = (token) => {
    if (token) {
        let roles = JSON.parse(window.atob(token.split(".")[1])).roles;
        if (roles) {
            if (typeof roles === "string") {
                return roles.includes("Admin") || roles.includes("Write");
            } else {
                return roles.find(role => role.includes("Admin") || role.includes("Write")) ? true : false;
            }
        } else {
            return false;
        }

    } else {
        return false;
    }
}