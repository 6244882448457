const searchValidateConst = {
    streetName: ["streetName"],
    streetBuildingId: ["streetName", "streetBuildingId"],
    floorId: ["streetName", "streetBuildingId", "floorId"],
    suiteId: ["streetName", "streetBuildingId", "floorId", "suiteId"],
    postCode: ["streetName", "postCode"],
    districtName: ["streetName", "districtName"],
    districtSubdivisionIdentifier: ["streetName", "postCode", "districtSubdivisionIdentifier"],
    municipalityName: ["streetName", "municipalityName"],
    isOfficial: []
}

export const validateSearch = (searchParams) => {
    let enableKeys = Object.keys(searchParams).filter(k => searchParams[k].length > 0);
    let allvalidators = enableKeys.reduce((a, b) => [...a, ...searchValidateConst[b]], []);
    return [...new Set(allvalidators)];
}

export const findSpacialChar = (value = "") => {
    const iChars = /[^\wÆÅØæåøÆÅØæåøäëïöüÄËÏÖÜéáèêóç,.-\s]/gm;
    for (let i = 0; i < value.length; i++) {
        if (iChars.test(value[i])) {
            return false;
        }
    }
    return true;
}

const errorMsg = {
    400: "Bad Request",
    401: "Unauthorized",
    403: "Forbidden",
    404: "Not Found",
    500: "Internal Server Error",
    501: "Not Implemented",
    502: "Bad Gateway",
    503: "Service Unavailable",
    504: "Gateway Timeout"
}

export const getErrorMsg = (msg) => {
    const errorCodes = ['400', '401', '403', '404', '500', '501', '502', '503', '504'];
    let errorCode = errorCodes.find(e => msg.includes(e));
    return errorCode ? errorMsg[errorCode] : "Something went wrong";
}