import React, { useEffect, useState } from 'react';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { UseAuthContext } from '../../context/AuthContext';

const containerStyles = {
    display: "flex",
    paddingLeft: "10px"
}
export default function Swagger() {
    const { token } = UseAuthContext();
    const [apiToken, setApiToken] = useState();
    const [urlLink, setUrlLink] = useState("Importer API Definition");
    useEffect(() => {
        setApiToken(token);
    }, [token])

    const requestInterceptor = (req) => {
        req.headers.Authorization = `Bearer ${apiToken}`;
        if (!req.url.includes("api-docs")) {
            let strAPI = req.url.split("/")[3];
            let host = req.url.split("/"+strAPI)[0];
            req.url = req.url.replace(host, "");
        }
        return req;
    };

    const handleClick = () => {
        urlLink === "Importer API Definition" ? setUrlLink("AM API Definition") : setUrlLink("Importer API Definition");
    }
    
    return (
        <div>
            <div><hr></hr></div>
            <div style={containerStyles}>
                <a href= "#" onClick={handleClick}>{"Switch to "+urlLink}</a>
            </div>
            {apiToken && <SwaggerUI url={urlLink === "Importer API Definition"? "/v3/api-docs" : "/importer/api-docs"}
                requestInterceptor={requestInterceptor} />}
        </div>
    )
}