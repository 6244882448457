
export const msalConfig = {
  auth: {
    //clientId: "cdc18013-103b-4fe8-9fe4-d7f315261d92",
    clientId: process.env.REACT_APP_CLIENTID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_REDIRECT_URL
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  //scopes: ["api://nuuday-address-management-service/.default"]
  scopes: [process.env.REACT_APP_SCOPES]
};
