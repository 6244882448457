import React, { useState } from 'react';
import axios from 'axios';
import { UseAuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlink } from '@fortawesome/free-solid-svg-icons';
import RegionFalloutTable from './RegionFalloutTable';
import MunicipalityFalloutTable from './MunicipalityFalloutTable';
import StreetFalloutTable from './StreetFalloutTable';
import PostFalloutTable from './PostFalloutTable';
import AccessFalloutTable from './AccessFalloutTable';
import SpecificFalloutTable from './SpecificFalloutTable';
import { getErrorMsg } from '../utils/commonUtil';

export default function Details() {
    const { t } = useTranslation();
    const { token } = UseAuthContext();
    const [type, setType] = useState('');
    const [resultData, setResultData] = useState({ data: null, loading: false, msg: '', active: '' });
    const [pageInfo, setPageInfo] = useState({
        page: 0,
        size: "10"
    })

    const fallout = {
        street: '/importer/v2/fallout/street',
        specific: '/importer/v2/fallout/specific',
        region: '/importer/v2/fallout/region',
        postal: '/importer/v2/fallout/postal',
        municipality: '/importer/v2/fallout/municipality',
        access: '/importer/v2/fallout/access',
    }

    const resetResult = () => setResultData({ data: null, loading: false, msg: '', active: '' });
    const resetFallout = () => {
        setResultData({ data: null, loading: false, msg: '', active: '' });
        setType('');
        setPageInfo({ page: 0, size: "10" });
    }

    const getResult = (pageNo) => {
        setResultData({ data: null, loading: true, msg: t('result.loading'), active: type });
        setPageInfo({ page: pageNo, size: pageInfo.size });
      //  process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
        const falloutUrl=process.env.REACT_APP_IMPORTER_URL;
        axios.get(`${fallout[type]}?offset=${pageNo}&pageSize=${pageInfo.size}`, 
        { headers: { "Authorization": `Bearer ${token}` } }).then(res => {
                if (res.data.length === 0) {
                    setResultData({ data: null, loading: false, msg: t('result.no_data'), active: type });
                
                } else {
                    setResultData({ data: res.data, loading: false, msg: "", active: type });
                }
            })
            .catch(err => {
                setResultData({ data: null, loading: false, msg: getErrorMsg(err.message), active: type });
            })
    }

    return (
        <div className="main-content">
            <div className="row ">
                <div className="col-lg-12 col-md-12">
                    <div className="card" style={{ minHeight: 200 }}>
                        <div className="card-content">
                            <div className="form-row">
                                <div className="form-group col-md-12">

                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link mr-1 active" id="search-tab-address" style={{ minWidth: "10rem" }} data-toggle="tab" href="#search_address" role="tab" aria-controls="search" aria-selected="true">
                                                Fallout
                                    </button>
                                        </li>

                                    </ul>
                                    <div className="row mt-2">
                                        <div className="col-sm-8">
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadioInline1" name="customRadioInline1" className="custom-control-input"
                                                    checked={type === "specific"}
                                                    onChange={() => { setType("specific"); resetResult() }} />
                                                <label className="custom-control-label" htmlFor="customRadioInline1">{t('fallout.specific')}</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadioInline2" name="customRadioInline1" className="custom-control-input"
                                                    checked={type === "access"}
                                                    onChange={() => { setType("access"); resetResult() }} />
                                                <label className="custom-control-label" htmlFor="customRadioInline2">{t('fallout.access')}</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadioInline3" name="customRadioInline1" className="custom-control-input"
                                                    checked={type === "postal"}
                                                    onChange={() => { setType("postal"); resetResult() }} />
                                                <label className="custom-control-label" htmlFor="customRadioInline3">{t('fallout.postal')}</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadioInline4" name="customRadioInline1" className="custom-control-input"
                                                    checked={type === "street"}
                                                    onChange={() => { setType("street"); resetResult() }} />
                                                <label className="custom-control-label" htmlFor="customRadioInline4">{t('fallout.street')}</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadioInline5" name="customRadioInline1" className="custom-control-input"
                                                    checked={type === "municipality"}
                                                    onChange={() => { setType("municipality"); resetResult() }} />
                                                <label className="custom-control-label" htmlFor="customRadioInline5">{t('fallout.municipality')}</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadioInline6" name="customRadioInline1" className="custom-control-input"
                                                    checked={type === "region"}
                                                    onChange={() => { setType("region"); resetResult() }} />
                                                <label className="custom-control-label" htmlFor="customRadioInline6">{t('fallout.region')}</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="row">
                                                <label htmlFor="records" className="col-sm-8 col-form-label text-right">{t('search.records')}</label>
                                                <div className="col-sm-4 align-self-center">
                                                    <select id="records" className="form-control form-control-sm"
                                                        value={pageInfo.size} onChange={(e) => setPageInfo(pre => ({ page: 0, size: e.target.value }))}>
                                                        <option value="10" >10</option>
                                                        <option value="20" >20</option>
                                                        <option value="50" >50</option>
                                                        <option value="100" >100</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 text-right">
                                    <button className="btn btn-primary"
                                        disabled={!type} onClick={() => getResult(0)}>{t('search.search')}</button>
                                </div>
                                <div className="col-6 text-left">
                                    <button className="btn btn-secondary ml-1" onClick={() => resetFallout()} >{t('create.reset')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12">
                    <div className="card" style={{ minHeight: 200, fontSize: "0.8rem" }}>
                        {!resultData.data ?
                            <div className="text-center py-4">
                                <FontAwesomeIcon icon={faUnlink} size="6x" color="#6c757d" />
                                <h3 className="mt-3">{resultData.msg ? resultData.msg : t('fallout.fallout')}</h3>
                            </div> :
                            <>
                                {resultData.active === 'region' && <RegionFalloutTable tableData={resultData.data} pageSubmit={getResult} />}
                                {resultData.active === 'municipality' && <MunicipalityFalloutTable tableData={resultData.data} pageSubmit={getResult} />}
                                {resultData.active === 'street' && <StreetFalloutTable tableData={resultData.data} pageSubmit={getResult} />}
                                {resultData.active === 'postal' && <PostFalloutTable tableData={resultData.data} pageSubmit={getResult} />}
                                {resultData.active === 'access' && <AccessFalloutTable tableData={resultData.data} pageSubmit={getResult} />}
                                {resultData.active === 'specific' && <SpecificFalloutTable tableData={resultData.data} pageNo={pageInfo.page} pageSubmit={getResult} />}
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}
