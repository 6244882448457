import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';

export default function GksPendingTable({ tableData, pageSubmit, syncType }) {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [select, setSelect] = useState("");
    const [page, setPage] = useState(1);
    const [goto, setGoto] = useState(1);
    const [errMsg, setErrMsg] = useState("");

    const officialStatus = {
        "0": "Official",
        "1": "TdcNet Unofficial",
        "8": "Nuuday Unofficial"
    }

    const invalidChars = [
        "-",
        "+",
        "e",
        "."
    ];

    useEffect(() => {

        setData(tableData.content);
        setPage(tableData.number);
        setGoto(tableData.number + 1);

    }, [tableData]);


    const handleGoto = () => {
        if (goto < 1) {
            setErrMsg(t('result.page_lesser'));
        } else if (goto > tableData.totalPages) {
            setErrMsg(t('result.page_greater'));
        } else {
            pageSubmit(syncType, goto - 1)
        }
    }

    return (
        <div className="p-2">
            <div className="row mb-2">
                <div className="col-sm-1 col-2 text-left">
                    <button className="btn btn-primary btn-sm" disabled={page < 1} onClick={() => pageSubmit(syncType, page - 1)} ><FontAwesomeIcon icon={faArrowAltCircleLeft} size="xl" /></button>
                </div>

                <div className="col-sm-10 col-8">
                    <div className="form-group row text-center ">
                        <label htmlFor="staticEmail" className="col-sm-2 col-12 col-form-label"> {t('result.page')}:  {page + 1} {t('result.of')} {tableData.totalPages}</label>
                        <label htmlFor="staticEmail" className="col-sm-3 col-8 col-form-label text-right"> {t('result.goto')}: </label>
                        <div className="col-sm-2 col-4">
                            <input type="number" className="form-control form-control-sm input-sm" id="page"
                                onKeyDown={(e) => invalidChars.includes(e.key) && e.preventDefault()} value={goto} onChange={(e) => setGoto(e.target.value)} />
                        </div>
                        <div className="col-sm-1 col-12 mb-1">
                            <button className="btn btn-secondary btn-sm " onClick={() => handleGoto()}>{t('result.go')}</button>
                        </div>
                    </div>
                </div>
                <div className="col-sm-1 col-2 text-right">
                    <button className="btn btn-primary btn-sm" disabled={page + 1 >= tableData.totalPages} onClick={() => pageSubmit(syncType, page + 1)}><FontAwesomeIcon icon={faArrowAltCircleRight} size="xl" /></button>
                </div>
                <div className="col-12 text-center">
                    {errMsg && <div className="alert alert-danger" style={{ marginBottom: "0", padding: "0.3rem" }} role="alert">{errMsg}</div>}
                </div>
            </div>
            <div className="table-responsive" style={{ maxHeight: "55vh", overflowY: "auto" }}>
                <table className="table table-hover table-sm table-striped table-bordered" style={{ whiteSpace: "nowrap" }}>
                    <thead>
                        <tr className="table-active" >
                            <th>{t('result.table.created')}</th>
                            <th>{t('result.table.status')}</th>
                            <th>{t('result.table.message')}</th>
                            <th>{t('result.table.street_name')}</th>
                            <th>{t('result.table.street_code')}</th>
                            <th>{t('result.table.building_id')}</th>
                            <th>{t('result.table.floor')}</th>
                            <th>{t('result.table.suite')}</th>
                            <th>{t('result.table.post_code')}</th>
                            <th>{t('result.table.district')}</th>
                            <th>{t('result.table.district_subdivision')}</th>
                            <th>{t('result.table.region')}</th>
                            <th>{t('result.table.municipality_name')}</th>
                            <th>{t('result.table.official')}</th>
                            <th>{t('result.table.deleted')}</th>
                            <th>{t('result.table.kvhx_id')}</th>
                            <th>{t('result.table.address_id')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 && data.map((d, i) => <tr key={i} className={select === d.id ? "sync_select" : null} onClick={() => setSelect(d.id)}>
                            <td>{d.created ? moment.utc(new Date(d.created + 'z'), 'MM-DD-YYYY').local().format("DD-MMM-YY hh:mm:A") : null}</td>
                            <td>{d?.status}</td>
                            <td>{d?.statusMessage}</td>
                            <td>{d.entry.accessAddress.streetId.name}</td>
                            <td>{d.entry.accessAddress.streetId.code}</td>
                            <td>{d.entry.accessAddress.streetBuildingId}</td>
                            <td>{d.entry?.floorId}</td>
                            <td>{d.entry?.suiteId}</td>
                            <td>{d.entry.accessAddress.postCodeId.id}</td>
                            <td>{d.entry.accessAddress.postCodeId.districtName}</td>
                            <td>{d.entry.accessAddress.districtSubDivId}</td>
                            <td>{d.entry.accessAddress.streetId.municipality.region.name}</td>
                            <td>{d.entry.accessAddress.streetId.municipality.municipalityName}</td>
                            <td>{officialStatus[d.entry.isOfficial]}</td>
                            <td>{d.entry.isDeleted}</td>
                            <td>{d.entry.kvhxId}</td>
                            <td>{d.entry.id}</td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    );
}