import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import {UseAuthContext} from '../../context/AuthContext';
import {getErrorMsg} from '../utils/commonUtil';

export default function SearchById({setResultData, setSearchByIdSubmit}) {
    const {t} = useTranslation();
    const {token} = UseAuthContext();
    const [load, setLoad] = useState(false);
    const [searchIds, setSearchIds] = useState("");
    const [idType, setIdType] = useState("");
    const [addressType, setAddressType] = useState("specific");
    const [pageInfo, setPageInfo] = useState({
        page: 0,
        size: "5"
    })

    const resetAll = () => {
        setSearchIds("");
        setIdType("");
        setPageInfo({
            page: 0,
            size: "5"
        });
        setResultData({data: null, loading: false, msg: ""});
    }

    const idUrls = {
        specific: {
            madId: "/api/v2/addresses/find-by-ids/legacy/SUBADDRESS?idType=MAD&",
            darId: "/api/v2/addresses/find-by-ids/legacy/SUBADDRESS?idType=DAR&",
            kvhxId: "/api/v2/addresses/find-by-ids/legacy/SUBADDRESS?idType=KVHX&",
            accessAddressId: "/api/v2/addresses/find-by-ids/legacy/SUBADDRESS?idType=ID&",
            specificAddressId: "/api/v2/addresses/find-by-ids/legacy/SUBADDRESS?idType=ID&"
        },
        access: {
            madId: "/api/v2/addresses/find-by-ids/legacy/ADDRESS?idType=MAD&",
            darId: "/api/v2/addresses/find-by-ids/legacy/ADDRESS?idType=DAR&",
            kvhxId: "/api/v2/addresses/find-by-ids/legacy/ADDRESS?idType=KVHX&",
            accessAddressId: "/api/v2/addresses/find-by-ids/legacy/ADDRESS?idType=ID&",
            specificAddressId: "/api/v2/addresses/find-by-ids/legacy/ADDRESS?idType=ID&"
        },
    }

    const handleSubmit = (pageNo) => {
        setLoad(true);
        setResultData({data: null, loading: true, msg: t('result.loading')});
        let idList = searchIds.split(/[\n|,|;|\s|\t]/).filter(id => id.trim() !== "");
        if (idType && idList.length > 0 && idList.length < 501) {
            axios.post(idUrls[addressType][idType] + `page=${pageNo}&size=${pageInfo.size}`, { id: idList }, { headers: { "Authorization": `Bearer ${token}` } })
                .then(res => {
                    if (res.data.content.length === 0) {
                        setResultData({data: null, loading: false, msg: t('result.no_data')});
                    } else {
                        if (addressType === 'access') {
                            setResultData({
                                data: {
                                    ...res.data,
                                    content: res.data.content.map((c, i) => {
                                        return {
                                            "accessAddress": c,
                                            'darId': c.darId,
                                            'id': "access-" + i,
                                            'isDeleted': c.isDeleted,
                                            'isOfficial': c.isOfficial,
                                            'kvhxId': c.kvhxId,
                                            'madId': c.madId
                                        }
                                    })
                                }
                            })
                    } else {
                        setResultData({data: res.data, loading: false, msg: ""});
                    }

                    }
                    setLoad(false);
                }).catch(err => {
                if (err.response.data.validationErrors) {
                    setResultData({data: null, loading: false, msg: err.response.data.validationErrors[0].message});
                } else {
                    setResultData({data: null, loading: false, msg: getErrorMsg(err.message)});
                }
                setLoad(false);
            })
        } else {
            if (idList.length === 0) {
                setResultData({data: null, loading: false, msg: t('result.invalid_id_request')});
            } else if (idList.length > 500) {
                setResultData({data: null, loading: false, msg: t('result.invalid_id_request500')});
            }
            setLoad(false);
        }
    }

    return (
        <div className="mt-3 text-sm">
            <div className="form-row gy-2">
                <div className=" col-md-6 col-sm-6">
                    <div className="row">
                        <div className='col-sm-4'>
                            <select id="records" className="form-control form-control-sm"
                                    value={addressType} onChange={(e) => setAddressType(e.target.value)}>
                                <option value="specific">Specific</option>
                                <option value="access">Access</option>
                            </select>
                        </div>
                        <div className="col-sm-8">
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline1" name="customRadioInline1"
                                       className="custom-control-input"
                                       checked={idType === "madId"}
                                       onChange={() => setIdType("madId")}/>
                                <label className="custom-control-label" htmlFor="customRadioInline1">Mad Id</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline2" name="customRadioInline1"
                                       className="custom-control-input"
                                       checked={idType === "darId"}
                                       onChange={() => setIdType("darId")}/>
                                <label className="custom-control-label" htmlFor="customRadioInline2">Dar Id</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline3" name="customRadioInline1"
                                       className="custom-control-input"
                                       checked={idType === "kvhxId"}
                                       onChange={() => setIdType("kvhxId")}/>
                                <label className="custom-control-label" htmlFor="customRadioInline3">Kvhx Id</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-5 col-md-6">
                    <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline4" name="customRadioInline1"
                               className="custom-control-input"
                               checked={idType === "specificAddressId"}
                               onChange={() => setIdType("specificAddressId")}/>
                        <label className="custom-control-label" htmlFor="customRadioInline4">Specific Address Id</label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline5" name="customRadioInline1"
                               className="custom-control-input"
                               checked={idType === "accessAddressId"}
                               onChange={() => setIdType("accessAddressId")}/>
                        <label className="custom-control-label" htmlFor="customRadioInline5">Access Address Id</label>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row">
                        <label htmlFor="records"
                               className="col-sm-10 col-form-label text-right">{t('search.records')}</label>
                        <div className="col-sm-2 align-self-center">
                            <select id="records" className="form-control form-control-sm"
                                    value={pageInfo.size}
                                    onChange={(e) => setPageInfo(pre => ({page: 0, size: e.target.value}))}>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                    </div>
                    <textarea type="text" className="form-control form-control-sm" rows="6" id="ids"
                              placeholder={"E.g.\n102DE662-C4F3-45AD-8A56-D23AF6C4A4C8\nAC7B899B-F7A9-42C9-8E37-2C584746A809\nor\n102DE662-C4F3-45AD-8A56-D23AF6C4A4C8, AC7B899B-F7A9-42C9-8E37-2C584746A809"}
                              value={searchIds}
                              onChange={(e) => setSearchIds(e.target.value)}/>
                </div>
            </div>

            <div className="row gy-1 mt-2">
                <div className="col-6 text-right">
                    <button className="btn btn-primary" disabled={load || !idType || !searchIds} onClick={() => {
                        handleSubmit(0);
                        setSearchByIdSubmit(() => handleSubmit)
                    }}>{load ? t('search.wait') : t('search.search')}</button>
                </div>
                <div className="col-6 text-left">
                    <button className="btn btn-secondary ml-1" onClick={() => resetAll()}>{t('search.reset')}</button>
                </div>
            </div>
        </div>
    )
}
