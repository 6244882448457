import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faArrowAltCircleLeft, faArrowAltCircleRight, faDeleteLeft, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { CSVLink } from "react-csv";
import Modal from 'react-modal';
import axios from 'axios';
import { UseAuthContext } from '../../context/AuthContext';
import { hasAccess } from '../../apis/access';
import moment from 'moment';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export default function FalloutTable({ tableData, pageSubmit }) {
    const { t } = useTranslation();
    const { token } = UseAuthContext();
    const [access, setAccess] = useState(false);
    const [data, setData] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [page, setPage] = useState(1);
    const [goto, setGoto] = useState(1);
    const [errMsg, setErrMsg] = useState("");
    const [deletedIds, setDeletedIds] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [msg, setMsg] = useState('');

    useEffect(() => {
        setData(tableData.content);
        setPage(tableData.number);
        setGoto(tableData.number + 1);
        setErrMsg("");
    }, [tableData])

    useEffect(() => {
        setAccess(hasAccess(token));
    }, [token])

    function openModal() {
        setMsg("");
        setIsOpen(true);
    }

    function closeModal() {
        setMsg("");
        setIsOpen(false);
    }

    const csvHeader = [t('result.table.street_name'),
    t('result.table.street_code'),
    t('result.table.building_id'),
    t('result.table.floor'),
    t('result.table.suite'),
    t('result.table.post_code'),
    t('result.table.district_subdivision'),
    t('result.table.municipality_name'),
    t('result.table.cause'),
    t('result.table.user'),
    t('result.table.created')];

    const getCsvData = (event, done) => {
        if (data.length > 0) {
            setCsvData([csvHeader, ...data.map(d => {
                return [
                    d.streetName,
                    d.streetCode,
                    d.streetBuildingId,
                    d.floorId,
                    d.suiteId,
                    d.postCode,
                    d.districtSubdivision,
                    d.municipalityName,
                    d.reason,
                    `${d?.userName} - ${d?.userMail}`,
                    d.created ? moment.utc(new Date(d.created + 'z'), 'MM-DD-YYYY').local().format("DD-MMM-YY hh:mm:A") : null
                ]
            })]);
        }
        done(true);
    }

    const handleGoto = () => {
        if (goto < 1) {
            setErrMsg(t('result.page_lesser'));
        } else if (goto > tableData.totalPages) {
            setErrMsg(t('result.page_greater'));
        } else {
            pageSubmit(goto - 1)
        }
    }

    const invalidChars = [
        "-",
        "+",
        "e",
        "."
    ];

    const handleCheck = (e) => {
        if (e.target.checked) {
            setDeletedIds(pre => [...pre, e.target.value]);
        } else {
            setDeletedIds(pre => pre.filter(i => i !== e.target.value));
        }
    }

    const deleteAllIds = (e) => {
        if (e.target.checked) {
            setDeletedIds(data.map(d => d.id));
        } else {
            setDeletedIds([]);
        }
    }

    const confirmedDelete = () => {
        setMsg("");
        axios.delete('/api/v1/fallout/deleteUnofficialAddressByIds', { "headers": { "Authorization": `Bearer ${token}` }, "data": { "id": deletedIds } })
            .then(res => {
                pageSubmit(page > 0 && data.length === 1 ? page - 1 : page);
            }).catch(err => {
                setMsg(t('fallout.unable_delete'))
            })
    }


    return (
        <div id="unofficial-fallout" className="card-content">
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                appElement={document.getElementById("unofficial-fallout")}
                contentLabel="fallout modal"
            >
                {msg ? <>
                    <h2>{msg}</h2>
                    <div className="text-center mt-3">
                        <button className="btn btn-outline-secondary ml-2" onClick={closeModal}>{t('fallout.cancel')}</button>
                    </div>
                </> :
                    <>
                        <h2>{t('fallout.confirm_delete')}</h2>
                        <p>{t('fallout.selected')} {deletedIds.length}</p>
                        <div className="text-center mt-3">
                            <button className="btn btn-outline-danger mr-2" disabled={deletedIds.length === 0}
                                onClick={() => confirmedDelete()}>{t('fallout.delete')}</button>
                            <button className="btn btn-outline-secondary ml-2" onClick={closeModal}>{t('fallout.cancel')}</button>
                        </div>
                    </>
                }
            </Modal>

            <div className="row mb-2">
                <div className="col-sm-1 col-2 text-left">
                    <button className="btn btn-primary btn-sm" disabled={page < 1} onClick={() => pageSubmit(page - 1)} ><FontAwesomeIcon icon={faArrowAltCircleLeft} size="xl" /></button>
                </div>

                <div className="col-sm-10 col-8">
                    <div className="form-group row text-center ">
                        <label htmlFor="staticEmail" className="col-sm-2 col-12 col-form-label"> {t('result.page')}:  {page + 1} {t('result.of')} {tableData.totalPages}</label>
                        <label htmlFor="staticEmail" className="col-sm-3 col-8 col-form-label text-right"> {t('result.goto')}: </label>
                        <div className="col-sm-2 col-4">
                            <input type="number" className="form-control form-control-sm input-sm" id="page"
                                onKeyDown={(e) => invalidChars.includes(e.key) && e.preventDefault()} value={goto} onChange={(e) => setGoto(e.target.value)} />
                        </div>
                        <div className="col-sm-1 col-12 mb-1">
                            <button className="btn btn-secondary btn-sm " onClick={() => handleGoto()}>{t('result.go')}</button>
                        </div>
                        <div className="col-sm-4 col-12">
                            <CSVLink data={csvData} filename={`nam-unofficial-page-${page}.csv`}
                                asyncOnClick={true}
                                onClick={getCsvData}>
                                <button className="btn btn-sm btn-primary text-right">{t('result.export')} <FontAwesomeIcon icon={faFileExport} /></button>
                            </CSVLink>
                        </div>
                    </div>
                </div>
                <div className="col-sm-1 col-2 text-right">
                    <button className="btn btn-primary btn-sm" disabled={page + 1 >= tableData.totalPages} onClick={() => pageSubmit(page + 1)}><FontAwesomeIcon icon={faArrowAltCircleRight} size="xl" /></button>
                </div>
                <div className="col-12 text-center">
                    {errMsg && <div className="alert alert-danger" style={{ marginBottom: "0", padding: "0.3rem" }} role="alert">{errMsg}</div>}
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-hover table-striped table-sm table-bordered" style={{ whiteSpace: "nowrap" }}>
                    <thead>
                        <tr className="table-active" >
                            {access && <th>
                                <div className="form-check delete-fallout" >
                                    <input className="form-check-input" type="checkbox" id="allcheck"
                                        checked={deletedIds.length === data.length} onChange={(e) => deleteAllIds(e)} />
                                    <FontAwesomeIcon icon={faTrashAlt} onClick={openModal}
                                        color={deletedIds.length > 0 ? "#DC143C" : null} />
                                </div>
                            </th>}
                            <th>{t('fallout.type')}</th>
                            <th>{t('result.table.street_name')}</th>
                            <th>{t('result.table.street_code')}</th>
                            <th>{t('result.table.building_id')}</th>
                            <th>{t('result.table.floor')}</th>
                            <th>{t('result.table.suite')}</th>
                            <th>{t('result.table.post_code')}</th>
                            <th>{t('result.table.district_subdivision')}</th>
                            <th>{t('result.table.municipality_name')}</th>
                            <th>{t('result.table.cause')}</th>
                            <th>{t('result.table.user')}</th>
                            <th>{t('result.table.created')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 && data.map((d, i) => <tr key={i}>
                            {access && <td>
                                <div className="form-check delete-fallout">
                                    <input className="form-check-input" type="checkbox" id={"check" + i}
                                        onChange={(e) => handleCheck(e)} checked={deletedIds.includes(d.id)} value={d.id} />
                                    <label style={{ cursor: 'pointer' }} className="form-check-label" htmlFor={"check" + i}>
                                        <FontAwesomeIcon icon={faDeleteLeft} color={deletedIds.includes(d.id) ? "#DC143C" : null} />
                                    </label>
                                </div>
                            </td>}
                            <td className={d.reason.includes("Edit") ? "fallout-green" : "fallout-red"}>{d.reason.includes("Edit") ? "Edit" : "Error"}</td>
                            <td>{d.streetName}</td>
                            <td>{d.streetCode}</td>
                            <td>{d.streetBuildingId}</td>
                            <td>{d.floorId}</td>
                            <td>{d.suiteId}</td>
                            <td>{d.postCode}</td>
                            <td>{d.districtSubdivision}</td>
                            <td>{d.municipalityName}</td>
                            <td>{d.reason}</td>
                            <td>{d?.userName} - {d?.userMail}</td>
                            <td>{d.created ? moment.utc(new Date(d.created + 'z'), 'MM-DD-YYYY').local().format("DD-MMM-YY hh:mm:A") : null}</td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
